import { DecimalPipe } from "@angular/common";

export class Reportes_response {
    tbl_usuario_id: number;
     nombre: string; 
     apellido_paterno: string; 
     numero_empleado: string; 
     servicio: string; 
     count_comida_reclamada:number; 
     precio_unitario:DecimalPipe; 
     subtotal:DecimalPipe; 
}

export class Reportes_excel_response {
     Número_empleado: string; 
     Nombre: string; 
     Apellidos: string; 
     Servicio: string; 
     Base: string;
     Otro: string
     Cantidad:number; 
     Precio_unitario:DecimalPipe; 
     Subtotal:DecimalPipe; 
}

export class Reportes_colaborador_response {
     tbl_usuario_id: number;
      nombre: string; 
      apellido_paterno: string; 
      numero_empleado: string; 
      fecha_captura: Date; 
      servicio: string; 
      precio_unitario:number; 
 }

 export class Reportes_colaborador_excel_response {
      nombre: string; 
      apellido_paterno: string; 
      numero_empleado: string; 
      hora_entrega: string;
      dia_entrega: string; 
      servicio: string; 
      base: string;
          otro: string
      precio_unitario:number; 
 }