import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, Subject } from "rxjs";
import { CatalogoRutas } from "../../model/Catalogos/CatalogoRutas";
import { LocalStorageService } from '../../services/local-storage.service';
import { AuthIdentity } from "./AuthIdentity";

@Injectable()
export class AuthGuard implements CanActivate {
  idPerfil: number;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private jwtHelper: JwtHelperService,
    private localStorageService: LocalStorageService
  ) {}
  // public ListModulos: any[];

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

  //     const subject = new Subject<boolean>();
  //     return this.authorizationService.isAuthorized(next.routeConfig.path).then(data=>{
  //       console.log('return en canActivate');
  //       // console.log(data);
  //       if(data)
  //       {
  //         // console.log('si autorizado');
  //         return true;
  //       }
  //       else
  //       {
  //         // console.log('no autorizado');
  //         this.router.navigate(['/ConsolaCentral/no-autorizado']);
  //       }

  //     });
  // }

  canActivate(): boolean {
    const token = localStorage.getItem("jwt");

    if (token && !this.jwtHelper.isTokenExpired(token, 300)) {
      this.idPerfil = AuthIdentity.ObtenerPerfilUsuarioSesion();
      if (this.idPerfil == 2) {
        return true;

      } else {
        localStorage.clear();
        this.router.navigate(["/"]);
        return false;
      }

      }
      else {
        localStorage.clear();
        this.router.navigate(["/"]);
        return false;
      }
 
  }

  public logOut(): void {
    localStorage.clear();
    // localStorage.removeItem("jwt");
    // localStorage.removeItem("jwtData");
  }
}

