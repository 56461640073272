import { JwtHelperService } from "@auth0/angular-jwt";

// Clase encarga de retornar el usuario actual en sesion
export class AuthIdentity {
  //#region Métodos publicos
  public static ObtenerUsuarioSesion(): any {
    const helper = new JwtHelperService();
    const informacionToken = helper.decodeToken(localStorage.getItem("jwt"));
    const usuario = informacionToken.UserData;
    if (usuario.length > 0) {
      return JSON.parse(usuario);
    } else {
      return null;
    }
  }

  public static ObtenerUsuarioRegistro(): any {
    const helper = new JwtHelperService();
    const informacionToken = helper.decodeToken(localStorage.getItem("jwt"));
    if (informacionToken != null && informacionToken != undefined) {
      var datosUsuarioJson = JSON.parse(informacionToken.UserData);

      return datosUsuarioJson.tbl_usuario_id;
    } else {
      return null;
    }
  }


  public static ObtenerPerfilUsuarioSesion(): any {
    const helper = new JwtHelperService();
    const informacionToken = helper.decodeToken(localStorage.getItem("jwt"));
    const usuario = informacionToken.UserData;
    if (usuario.length > 0) {
      var datosUsuarioJson = JSON.parse(usuario);
      return JSON.parse(datosUsuarioJson.tbl_perfil_id);
    } else {
      return null;
    }
  }
  //#endregion
}
