export class usuario_model_response_carga_masiva
{
  // nombre:string;
  // m_nombre:string[];
  // apellido_paterno:string;
  // m_apellido_paterno:string[];
  // apellido_materno:string;
  // m_apellido_materno:string[];
  // usuario: string;
  // m_usuario: string[];
  // telefono: string;
  // m_telefono: string[];
  // fecha_nacimiento: string;
  // m_fecha_nacimiento: string[];
  // correo_electronico: string;
  // m_correo_electronico: string[];
  // dispositivo: string;
  // m_dispositivo: string[];
  // datos_dispositivo: string[];
  // edificio: string;
  // m_edificio: string[];
  // piso: string;
  // m_piso: string[];
  // area: string;
  // m_area: string[];
  // subarea: number;
  // m_subarea: string[];
  // espacio: boolean;
  // m_espacio: string[];
  // comentario: string;
  // valido:boolean;
  // usuario_info_id: string;
  // usuario_id: string;
  // id_instancia: string;


  nombre: string;
  apellido_paterno: string;
  apellido_materno: string;
  numero_empleado: number;
  curp: string;
  fecha_nacimiento: Date;
  calle: string;
  numero_ext: string;
  numero_int: string;
  cpostal: number;
  correo: string
  telefono: number;
  es_alergico: boolean;
  requiere_pin: boolean;
  pin: string;

}
