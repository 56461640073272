import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { Lista_Conf_horario_tipo_comida_modelo_response, Lista_tipo_comidas_modelo_response } from 'src/model/Catalogos/TipoComida';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';

@Component({
  selector: 'app-tipo-comida',
  templateUrl: './tipo-comida.component.html',
  styleUrls: ['./tipo-comida.component.css'],
  providers: [ServiceGenerico]

})
export class TipoComidaComponent implements OnInit {
  @ViewChild("modalAddTipoComida", { static: false }) modalAddTipoComida: TemplateRef<any>;
  @ViewChild("modalConfHorarioTipoComida", { static: false }) modalConfHorarioTipoComida: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarTipoComida: NgbModalRef;
  modalConfiguracionHorarioTipoComida: NgbModalRef;
  modalEliminarTipoComidaColaborador: NgbModalRef;

  formGroupAgregarTipoComida: FormGroup;
  formGroupConfHorarioTipoComida: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  listaTipoComidas: Lista_tipo_comidas_modelo_response[];
  TipoComida: Lista_tipo_comidas_modelo_response;
  ConfRelHorarioTipoComida: Lista_Conf_horario_tipo_comida_modelo_response;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  public tipoComidaId: string = "";
  

  _tbl_tipo_comida_id: number;
  b_p_lunes: boolean;
  b_p_martes: boolean;
  b_p_miercoles: boolean;
  b_p_jueves: boolean;
  b_p_viernes: boolean;
  b_p_sabado: boolean;
  b_p_domingo: boolean;
  validaChecks: boolean = true;
  _rel_horario_tipo_comida_id: number;
  _nombre_tipo_comida: string;
  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private fb1: FormBuilder,
  ) { 
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerTipoComidas();
    this.inicializarFormAgregarTipoComida();
    this.inicializarFormConfHorarioTipoComida();
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  obtenerTipoComidas() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/TipoComidas/GetListaTipoComidas?p_estatus=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaTipoComidas = [] = tempdate.response as Lista_tipo_comidas_modelo_response[];
            this.renderTabla();
          } else {
            this.listaTipoComidas = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarTipoComida() {
    this.formGroupAgregarTipoComida = this.fb.group({
      p_nombre: ["", Validators.required],
      p_descripcion: [""],
      p_precio_unitario: [""],
    });
  }

  inicializarFormConfHorarioTipoComida() {
    this.formGroupConfHorarioTipoComida = this.fb.group({
      p_lunes: [0],
      p_martes: [0],
      p_miercoles: [0],
      p_jueves: [0],
      p_viernes: [0],
      p_sabado: [0],
      p_domingo: [0],
      p_h_lunes_i: [''],
      p_h_martes_i: [''],
      p_h_miercoles_i: [''],
      p_h_jueves_i: [''],
      p_h_viernes_i: [''],
      p_h_sabado_i: [''],
      p_h_domingo_i: [''],
      p_h_lunes_f: [''],
      p_h_martes_f: [''],
      p_h_miercoles_f: [''],
      p_h_jueves_f: [''],
      p_h_viernes_f: [''],
      p_h_sabado_f: [''],
      p_h_domingo_f: [''],
      p_dia_fin_1:[0],
      p_dia_fin_2:[0],
      p_dia_fin_3:[0],
      p_dia_fin_4:[0],
      p_dia_fin_5:[0],
      p_dia_fin_6:[0],
      p_dia_fin_0:[0],
    });
  }


  GuardarTipoComida() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarTipoComida.value;
    model.p_tbl_tipo_comida_id = this._tbl_tipo_comida_id;
    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/TipoComidas/PostTipoComida")
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerTipoComidas();
            this.modalAgregarTipoComida.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/TipoComidas/PutTipoComida")
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerTipoComidas();
            this.modalAgregarTipoComida.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }


  }
  agregarTipoComida() {
    this.funcionalidad = 'Nuevo';
    this._tbl_tipo_comida_id = 0;
    this.openModalFormTipoComida(true);
  }

  verDetalleTipoComida(tbl_tipo_comida_id){
    this.esEditable = false;
    this.openModalFormTipoComida(false);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/TipoComidas/GetTipoComida_x_id?p_tbl_tipo_comida_id=" + tbl_tipo_comida_id)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.TipoComida = tempdate.response as Lista_tipo_comidas_modelo_response;
            this.formGroupAgregarTipoComida.value.p_nombre = this.TipoComida[0].nombre;
            this.formGroupAgregarTipoComida.value.p_descripcion = this.TipoComida[0].descripcion;
            this.formGroupAgregarTipoComida.value.p_precio_unitario = this.TipoComida[0].precio_unitario;
            this.formGroupAgregarTipoComida.patchValue(this.formGroupAgregarTipoComida.value);
          } else {
            this.listaTipoComidas = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );  
    }
  
  editarTipoComida(tbl_tipo_comida_id) {
    this.funcionalidad = 'Editar';
    this._tbl_tipo_comida_id = tbl_tipo_comida_id;
    this.openModalFormTipoComida(true);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/TipoComidas/GetTipoComida_x_id?p_tbl_tipo_comida_id=" + tbl_tipo_comida_id)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.TipoComida = tempdate.response as Lista_tipo_comidas_modelo_response;
            this.formGroupAgregarTipoComida.value.p_nombre = this.TipoComida[0].nombre;
            this.formGroupAgregarTipoComida.value.p_descripcion = this.TipoComida[0].descripcion;
            this.formGroupAgregarTipoComida.value.p_precio_unitario = this.TipoComida[0].precio_unitario;
            this.formGroupAgregarTipoComida.patchValue(this.formGroupAgregarTipoComida.value);
          } else {
            this.listaTipoComidas = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  
  eliminarTipoComida(tbl_tipo_comida_id) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/TipoComidas/DeleteTipoComida?p_tbl_tipo_comida_id=" + tbl_tipo_comida_id.tipoComidaId)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerTipoComidas();
            this.modalEliminarTipoComidaColaborador.close();

          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  GuardarHorarioTipoComida() {
    const mensaje = 'No se han capturado todos los horarios';
    var validaHorario = true; 
    if (this.formGroupConfHorarioTipoComida.get("p_lunes").value == 1 && (this.formGroupConfHorarioTipoComida.get("p_h_lunes_i").value == "" || this.formGroupConfHorarioTipoComida.get("p_h_lunes_f").value == "")) {
      validaHorario = false; 
    } 
    if (this.formGroupConfHorarioTipoComida.get("p_martes").value == 1 && (this.formGroupConfHorarioTipoComida.get("p_h_martes_i").value == "" || this.formGroupConfHorarioTipoComida.get("p_h_martes_f").value == "")) {
      validaHorario = false; 
    }
    if (this.formGroupConfHorarioTipoComida.get("p_miercoles").value == 1 && (this.formGroupConfHorarioTipoComida.get("p_h_miercoles_i").value == "" || this.formGroupConfHorarioTipoComida.get("p_h_miercoles_f").value == "")) {
      validaHorario = false; 
    }
    if (this.formGroupConfHorarioTipoComida.get("p_jueves").value == 1 && (this.formGroupConfHorarioTipoComida.get("p_h_jueves_i").value == "" || this.formGroupConfHorarioTipoComida.get("p_h_jueves_f").value == "")) {
      validaHorario = false; 
    }
    if (this.formGroupConfHorarioTipoComida.get("p_viernes").value == 1 && (this.formGroupConfHorarioTipoComida.get("p_h_viernes_i").value == "" || this.formGroupConfHorarioTipoComida.get("p_h_viernes_f").value == "")) {
      validaHorario = false; 
    }
    if (this.formGroupConfHorarioTipoComida.get("p_sabado").value == 1 && (this.formGroupConfHorarioTipoComida.get("p_h_sabado_i").value == "" || this.formGroupConfHorarioTipoComida.get("p_h_sabado_f").value == "")) {
      validaHorario = false; 
    }
    if (this.formGroupConfHorarioTipoComida.get("p_domingo").value == 1 && (this.formGroupConfHorarioTipoComida.get("p_h_domingo_i").value == "" || this.formGroupConfHorarioTipoComida.get("p_h_domingo_f").value == "")) {
      validaHorario = false; 
    }

    if (!validaHorario) {
      this.openMensajes(mensaje, true, '');
    } 
    else {
      this.operacionRespuesta.EstaEjecutando = true;

      if (this._rel_horario_tipo_comida_id != 0) {
        if (this.formGroupConfHorarioTipoComida.value.p_lunes == true) {
          this.formGroupConfHorarioTipoComida.value.p_lunes = 1;
          this.formGroupConfHorarioTipoComida.get("p_dia_fin_1").patchValue(parseInt(this.formGroupConfHorarioTipoComida.value.p_dia_fin_1))
        } else {
          this.formGroupConfHorarioTipoComida.value.p_lunes = 0;
        }
        if (this.formGroupConfHorarioTipoComida.value.p_martes == true) {
          this.formGroupConfHorarioTipoComida.value.p_martes = 1;
          this.formGroupConfHorarioTipoComida.get("p_dia_fin_2").patchValue(parseInt(this.formGroupConfHorarioTipoComida.value.p_dia_fin_2))
        } else {
          this.formGroupConfHorarioTipoComida.value.p_martes = 0;
        }
        if (this.formGroupConfHorarioTipoComida.value.p_miercoles == true) {
          this.formGroupConfHorarioTipoComida.value.p_miercoles = 1;
          this.formGroupConfHorarioTipoComida.get("p_dia_fin_3").patchValue(parseInt(this.formGroupConfHorarioTipoComida.value.p_dia_fin_3))
        } else {
          this.formGroupConfHorarioTipoComida.value.p_miercoles = 0;
        }
        if (this.formGroupConfHorarioTipoComida.value.p_jueves == true) {
          this.formGroupConfHorarioTipoComida.value.p_jueves = 1;
          this.formGroupConfHorarioTipoComida.get("p_dia_fin_4").patchValue(parseInt(this.formGroupConfHorarioTipoComida.value.p_dia_fin_4))
        } else {
          this.formGroupConfHorarioTipoComida.value.p_jueves = 0;
        }
        if (this.formGroupConfHorarioTipoComida.value.p_viernes == true) {
          this.formGroupConfHorarioTipoComida.value.p_viernes = 1;
          this.formGroupConfHorarioTipoComida.get("p_dia_fin_5").patchValue(parseInt(this.formGroupConfHorarioTipoComida.value.p_dia_fin_5))
        } else {
          this.formGroupConfHorarioTipoComida.value.p_viernes = 0;
        }
        if (this.formGroupConfHorarioTipoComida.value.p_sabado == true) {
          this.formGroupConfHorarioTipoComida.value.p_sabado = 1;
          this.formGroupConfHorarioTipoComida.get("p_dia_fin_6").patchValue(parseInt(this.formGroupConfHorarioTipoComida.value.p_dia_fin_6))
        } else {
          this.formGroupConfHorarioTipoComida.value.p_sabado = 0;
        }
        if (this.formGroupConfHorarioTipoComida.value.p_domingo == true) {
          this.formGroupConfHorarioTipoComida.value.p_domingo = 1;
          this.formGroupConfHorarioTipoComida.get("p_dia_fin_0").patchValue(parseInt(this.formGroupConfHorarioTipoComida.value.p_dia_fin_0))
        } else {
          this.formGroupConfHorarioTipoComida.value.p_domingo = 0;
        }
      }

      if (this.formGroupConfHorarioTipoComida.get("p_lunes").value == false || this.formGroupConfHorarioTipoComida.get("p_lunes").value == 0 ) {
        this.formGroupConfHorarioTipoComida.get("p_lunes").patchValue(0);
      } else {
        this.formGroupConfHorarioTipoComida.get("p_lunes").patchValue(1);
      }
      if (this.formGroupConfHorarioTipoComida.get("p_martes").value == false || this.formGroupConfHorarioTipoComida.get("p_martes").value == 0) {
        this.formGroupConfHorarioTipoComida.get("p_martes").patchValue(0);
      } else {
        this.formGroupConfHorarioTipoComida.get("p_martes").patchValue(1);
      }
      if (this.formGroupConfHorarioTipoComida.get("p_miercoles").value == false || this.formGroupConfHorarioTipoComida.get("p_miercoles").value == 0) {
        this.formGroupConfHorarioTipoComida.get("p_miercoles").patchValue(0);
      } else {
        this.formGroupConfHorarioTipoComida.get("p_miercoles").patchValue(1);
      }
      if (this.formGroupConfHorarioTipoComida.get("p_jueves").value == false || this.formGroupConfHorarioTipoComida.get("p_jueves").value == 0) {
        this.formGroupConfHorarioTipoComida.get("p_jueves").patchValue(0);
      } else {
        this.formGroupConfHorarioTipoComida.get("p_jueves").patchValue(1);
      }
      if (this.formGroupConfHorarioTipoComida.get("p_viernes").value == false || this.formGroupConfHorarioTipoComida.get("p_viernes").value == 0) {
        this.formGroupConfHorarioTipoComida.get("p_viernes").patchValue(0);
      } else {
        this.formGroupConfHorarioTipoComida.get("p_viernes").patchValue(1);
      }
      if (this.formGroupConfHorarioTipoComida.get("p_sabado").value == false || this.formGroupConfHorarioTipoComida.get("p_sabado").value == 0) {
        this.formGroupConfHorarioTipoComida.get("p_sabado").patchValue(0);
      } else {
        this.formGroupConfHorarioTipoComida.get("p_sabado").patchValue(1);
      }
      if (this.formGroupConfHorarioTipoComida.get("p_domingo").value == false || this.formGroupConfHorarioTipoComida.get("p_domingo").value == 0) {
        this.formGroupConfHorarioTipoComida.get("p_domingo").patchValue(0);
      } else {
        this.formGroupConfHorarioTipoComida.get("p_domingo").patchValue(1);
      }

      let model = this.formGroupConfHorarioTipoComida.value;
      model.p_rel_horario_tipo_comida_id = this._rel_horario_tipo_comida_id;
      model.p_tbl_tipo_comida_id = this._tbl_tipo_comida_id;
       this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/ConfiguracionHorario/PostConfHorarioTipoComida")
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerTipoComidas();
            this.modalConfiguracionHorarioTipoComida.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    } 

  }

openModalFormTipoComida(esEditable:boolean) {
  this.esEditable = esEditable;
  this.formGroupAgregarTipoComida.reset({nombre:'', descripcion:''});
  this.modalAgregarTipoComida = this.modalService.open(this.modalAddTipoComida, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static'  });
}

  openModalConfHorarioTipoComida(tbl_tipo_comida_id, nombre_t_comida) {
    this._nombre_tipo_comida = nombre_t_comida;
    this._tbl_tipo_comida_id = tbl_tipo_comida_id;
    this.b_p_lunes = true;
    this.b_p_martes = true;
    this.b_p_miercoles = true;
    this.b_p_jueves = true;
    this.b_p_viernes = true;
    this.b_p_sabado = true;
    this.b_p_domingo = true;
    this.formGroupConfHorarioTipoComida.reset({
      p_lunes: 0, p_martes: 0, p_miercoles: 0, p_jueves: 0, p_viernes: 0, p_sabado: 0, p_domingo: 0,
      p_h_lunes_i: '', p_h_martes_i: '', p_h_miercoles_i: '', p_h_jueves_i: '', p_h_viernes_i: '', p_h_sabado_i: '', p_h_domingo_i: '',
      p_h_lunes_f: '', p_h_martes_f: '', p_h_miercoles_f: '', p_h_jueves_f: '', p_h_viernes_f: '', p_h_sabado_f: '', p_h_domingo_f: '',
      p_dia_fin_1: 1, p_dia_fin_2: 2, p_dia_fin_3: 3, p_dia_fin_4: 4, p_dia_fin_5: 5, p_dia_fin_6: 6, p_dia_fin_0: 0 
    });

    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/ConfiguracionHorario/GetConfHorarioTipoComida?p_tbl_tipo_comida_id=" + tbl_tipo_comida_id)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.ConfRelHorarioTipoComida = tempdate.response as Lista_Conf_horario_tipo_comida_modelo_response;

            this._rel_horario_tipo_comida_id = this.ConfRelHorarioTipoComida[0].rel_horario_tipo_comida_id;

            this.formGroupConfHorarioTipoComida.value.p_lunes = this.ConfRelHorarioTipoComida[0].lunes;
            if (this.ConfRelHorarioTipoComida[0].h_lunes_i.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_lunes_i.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_lunes_i.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_lunes_i.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_lunes_i.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_lunes_i.minutes;
            }
            
            if (this.ConfRelHorarioTipoComida[0].h_lunes_f.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_lunes_f.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_lunes_f.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_lunes_f.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_lunes_f.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_lunes_f.minutes;
            }

            if (this.ConfRelHorarioTipoComida[0].lunes) {
              this.formGroupConfHorarioTipoComida.value.p_h_lunes_i = this.ConfRelHorarioTipoComida[0].h_lunes_i.hours + ":" + this.ConfRelHorarioTipoComida[0].h_lunes_i.minutes;
              this.formGroupConfHorarioTipoComida.value.p_h_lunes_f = this.ConfRelHorarioTipoComida[0].h_lunes_f.hours + ":" + this.ConfRelHorarioTipoComida[0].h_lunes_f.minutes;
              this.formGroupConfHorarioTipoComida.value.p_dia_fin_1 = this.ConfRelHorarioTipoComida[0].dia_fin_1;
              this.b_p_lunes = false;
            } else {
              this.formGroupConfHorarioTipoComida.value.p_h_lunes_i = "";
              this.formGroupConfHorarioTipoComida.value.p_h_lunes_f = "";
            }


            this.formGroupConfHorarioTipoComida.value.p_martes = this.ConfRelHorarioTipoComida[0].martes;
            if (this.ConfRelHorarioTipoComida[0].h_martes_i.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_martes_i.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_martes_i.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_martes_i.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_martes_i.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_martes_i.minutes;
            }
            
            if (this.ConfRelHorarioTipoComida[0].h_martes_f.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_martes_f.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_martes_f.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_martes_f.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_martes_f.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_martes_f.minutes;
            }

            if (this.ConfRelHorarioTipoComida[0].martes) {
              this.formGroupConfHorarioTipoComida.value.p_h_martes_i = this.ConfRelHorarioTipoComida[0].h_martes_i.hours + ":" + this.ConfRelHorarioTipoComida[0].h_martes_i.minutes;
              this.formGroupConfHorarioTipoComida.value.p_h_martes_f = this.ConfRelHorarioTipoComida[0].h_martes_f.hours + ":" + this.ConfRelHorarioTipoComida[0].h_martes_f.minutes;
              this.formGroupConfHorarioTipoComida.value.p_dia_fin_2 = this.ConfRelHorarioTipoComida[0].dia_fin_2;
              this.b_p_martes = false;
            } else {
              this.formGroupConfHorarioTipoComida.value.p_h_martes_i = "";
              this.formGroupConfHorarioTipoComida.value.p_h_martes_f = "";
            }


            this.formGroupConfHorarioTipoComida.value.p_miercoles = this.ConfRelHorarioTipoComida[0].miercoles;
            if (this.ConfRelHorarioTipoComida[0].h_miercoles_i.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_miercoles_i.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_miercoles_i.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_miercoles_i.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_miercoles_i.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_miercoles_i.minutes;
            }
            
            if (this.ConfRelHorarioTipoComida[0].h_miercoles_f.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_miercoles_f.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_miercoles_f.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_miercoles_f.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_miercoles_f.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_miercoles_f.minutes;
            }

            if (this.ConfRelHorarioTipoComida[0].miercoles) {
              this.b_p_miercoles = false;
              this.formGroupConfHorarioTipoComida.value.p_h_miercoles_i = this.ConfRelHorarioTipoComida[0].h_miercoles_i.hours + ":" + this.ConfRelHorarioTipoComida[0].h_miercoles_i.minutes;
              this.formGroupConfHorarioTipoComida.value.p_h_miercoles_f = this.ConfRelHorarioTipoComida[0].h_miercoles_f.hours + ":" + this.ConfRelHorarioTipoComida[0].h_miercoles_f.minutes;
              this.formGroupConfHorarioTipoComida.value.p_dia_fin_3 = this.ConfRelHorarioTipoComida[0].dia_fin_3;
            } else {
              this.formGroupConfHorarioTipoComida.value.p_h_miercoles_i = "";
              this.formGroupConfHorarioTipoComida.value.p_h_miercoles_f = "";
            }

            this.formGroupConfHorarioTipoComida.value.p_jueves = this.ConfRelHorarioTipoComida[0].jueves;
            if (this.ConfRelHorarioTipoComida[0].h_jueves_i.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_jueves_i.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_jueves_i.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_jueves_i.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_jueves_i.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_jueves_i.minutes;
            }
            
            if (this.ConfRelHorarioTipoComida[0].h_jueves_f.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_jueves_f.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_jueves_f.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_jueves_f.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_jueves_f.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_jueves_f.minutes;
            }

            if (this.ConfRelHorarioTipoComida[0].jueves) {
              this.b_p_jueves = false;
              this.formGroupConfHorarioTipoComida.value.p_h_jueves_i = this.ConfRelHorarioTipoComida[0].h_jueves_i.hours + ":" + this.ConfRelHorarioTipoComida[0].h_jueves_i.minutes;
              this.formGroupConfHorarioTipoComida.value.p_h_jueves_f = this.ConfRelHorarioTipoComida[0].h_jueves_f.hours + ":" + this.ConfRelHorarioTipoComida[0].h_jueves_f.minutes;
              this.formGroupConfHorarioTipoComida.value.p_dia_fin_4 = this.ConfRelHorarioTipoComida[0].dia_fin_4;
            } else {
              this.formGroupConfHorarioTipoComida.value.p_h_jueves_i = "";
              this.formGroupConfHorarioTipoComida.value.p_h_jueves_f = "";
            }

            this.formGroupConfHorarioTipoComida.value.p_viernes = this.ConfRelHorarioTipoComida[0].viernes;
            if (this.ConfRelHorarioTipoComida[0].h_viernes_i.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_viernes_i.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_viernes_i.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_viernes_i.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_viernes_i.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_viernes_i.minutes;
            }
            
            if (this.ConfRelHorarioTipoComida[0].h_viernes_f.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_viernes_f.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_viernes_f.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_viernes_f.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_viernes_f.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_viernes_f.minutes;
            }

            if (this.ConfRelHorarioTipoComida[0].viernes) {
              this.b_p_viernes = false;
              this.formGroupConfHorarioTipoComida.value.p_h_viernes_i = this.ConfRelHorarioTipoComida[0].h_viernes_i.hours + ":" + this.ConfRelHorarioTipoComida[0].h_viernes_i.minutes;
              this.formGroupConfHorarioTipoComida.value.p_h_viernes_f = this.ConfRelHorarioTipoComida[0].h_viernes_f.hours + ":" + this.ConfRelHorarioTipoComida[0].h_viernes_f.minutes;
              this.formGroupConfHorarioTipoComida.value.p_dia_fin_5 = this.ConfRelHorarioTipoComida[0].dia_fin_5;
            } else {
              this.formGroupConfHorarioTipoComida.value.p_h_viernes_i = "";
              this.formGroupConfHorarioTipoComida.value.p_h_viernes_f = "";
            }

            this.formGroupConfHorarioTipoComida.value.p_sabado = this.ConfRelHorarioTipoComida[0].sabado;
            if (this.ConfRelHorarioTipoComida[0].h_sabado_i.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_sabado_i.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_sabado_i.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_sabado_i.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_sabado_i.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_sabado_i.minutes;
            }
            
            if (this.ConfRelHorarioTipoComida[0].h_sabado_f.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_sabado_f.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_sabado_f.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_sabado_f.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_sabado_f.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_sabado_f.minutes;
            }

            if (this.ConfRelHorarioTipoComida[0].sabado) {
              this.b_p_sabado = false;
              this.formGroupConfHorarioTipoComida.value.p_h_sabado_i = this.ConfRelHorarioTipoComida[0].h_sabado_i.hours + ":" + this.ConfRelHorarioTipoComida[0].h_sabado_i.minutes;
              this.formGroupConfHorarioTipoComida.value.p_h_sabado_f = this.ConfRelHorarioTipoComida[0].h_sabado_f.hours + ":" + this.ConfRelHorarioTipoComida[0].h_sabado_f.minutes;
              this.formGroupConfHorarioTipoComida.value.p_dia_fin_6 = this.ConfRelHorarioTipoComida[0].dia_fin_6;
            } else {
              this.formGroupConfHorarioTipoComida.value.p_h_sabado_i = "";
              this.formGroupConfHorarioTipoComida.value.p_h_sabado_f = "";
            }

            this.formGroupConfHorarioTipoComida.value.p_domingo = this.ConfRelHorarioTipoComida[0].domingo;
            if (this.ConfRelHorarioTipoComida[0].h_domingo_i.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_domingo_i.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_domingo_i.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_domingo_i.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_domingo_i.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_domingo_i.minutes;
            }
            
            if (this.ConfRelHorarioTipoComida[0].h_domingo_f.hours.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_domingo_f.hours =  "0" + this.ConfRelHorarioTipoComida[0].h_domingo_f.hours;
            }
            if (this.ConfRelHorarioTipoComida[0].h_domingo_f.minutes.toString().length == 1) {
              this.ConfRelHorarioTipoComida[0].h_domingo_f.minutes =  "0" + this.ConfRelHorarioTipoComida[0].h_domingo_f.minutes;
            }

            if (this.ConfRelHorarioTipoComida[0].domingo) {
              this.b_p_domingo = false;
              this.formGroupConfHorarioTipoComida.value.p_h_domingo_i = this.ConfRelHorarioTipoComida[0].h_domingo_i.hours + ":" + this.ConfRelHorarioTipoComida[0].h_domingo_i.minutes;
              this.formGroupConfHorarioTipoComida.value.p_h_domingo_f = this.ConfRelHorarioTipoComida[0].h_domingo_f.hours + ":" + this.ConfRelHorarioTipoComida[0].h_domingo_f.minutes;
              this.formGroupConfHorarioTipoComida.value.p_dia_fin_0 = this.ConfRelHorarioTipoComida[0].dia_fin_0;
            } else {
              this.formGroupConfHorarioTipoComida.value.p_h_domingo_i = "";
              this.formGroupConfHorarioTipoComida.value.p_h_domingo_f = "";
            }
            this.formGroupConfHorarioTipoComida.patchValue(this.formGroupConfHorarioTipoComida.value);

            this.validaChecks = false;

          } else {
            this.validaChecks = true;
            this._rel_horario_tipo_comida_id = 0;
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    this.modalConfiguracionHorarioTipoComida = this.modalService.open(this.modalConfHorarioTipoComida, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
    
  }

  onChangeCheck(check) {
    if (check == 'p_lunes') {
      const checkp_lunes = this.formGroupConfHorarioTipoComida.get("p_lunes").value;
      if (checkp_lunes == true) {
        this.formGroupConfHorarioTipoComida.get("p_lunes").patchValue(1);
        this.b_p_lunes = false;
      } else {
        this.b_p_lunes = true;
        this.formGroupConfHorarioTipoComida.get("p_h_lunes_i").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_h_lunes_f").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_dia_fin_1").patchValue(1);
      }
    }
    if (check == 'p_martes') {
      const checkp_martes = this.formGroupConfHorarioTipoComida.get("p_martes").value;
      if (checkp_martes == true) {
        this.formGroupConfHorarioTipoComida.get("p_martes").patchValue(1);
        this.b_p_martes = false;
      } else {
        this.b_p_martes = true;
        this.formGroupConfHorarioTipoComida.get("p_h_martes_i").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_h_martes_f").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_dia_fin_2").patchValue(2);
      }
    }
    if (check == 'p_miercoles') {
      const checkp_miercoles = this.formGroupConfHorarioTipoComida.get("p_miercoles").value;
      if (checkp_miercoles == true) {
        this.formGroupConfHorarioTipoComida.get("p_miercoles").patchValue(1);
        this.b_p_miercoles = false;
      } else {
        this.b_p_miercoles = true;
        this.formGroupConfHorarioTipoComida.get("p_h_miercoles_i").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_h_miercoles_f").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_dia_fin_3").patchValue(3);
      }
    }
    if (check == 'p_jueves') {
      const checkp_jueves = this.formGroupConfHorarioTipoComida.get("p_jueves").value;
      if (checkp_jueves == true) {
        this.formGroupConfHorarioTipoComida.get("p_jueves").patchValue(1);
        this.b_p_jueves = false;
      } else {
        this.b_p_jueves = true;
        this.formGroupConfHorarioTipoComida.get("p_h_jueves_i").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_h_jueves_f").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_dia_fin_4").patchValue(4);
      }
    }
    if (check == 'p_viernes') {
      const checkp_viernes = this.formGroupConfHorarioTipoComida.get("p_viernes").value;
      if (checkp_viernes == true) {
        this.formGroupConfHorarioTipoComida.get("p_viernes").patchValue(1);
        this.b_p_viernes = false;
      } else {
        this.b_p_viernes = true;
        this.formGroupConfHorarioTipoComida.get("p_h_viernes_i").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_h_viernes_f").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_dia_fin_5").patchValue(5);
      }
    }
    if (check == 'p_sabado') {
      const checkp_sabado = this.formGroupConfHorarioTipoComida.get("p_sabado").value;
      if (checkp_sabado == true) {
        this.formGroupConfHorarioTipoComida.get("p_sabado").patchValue(1);
        this.b_p_sabado = false;
      } else {
        this.b_p_sabado = true;
        this.formGroupConfHorarioTipoComida.get("p_h_sabado_i").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_h_sabado_f").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_dia_fin_6").patchValue(6);
      }
    }
    if (check == 'p_domingo') {
      const checkp_domingo = this.formGroupConfHorarioTipoComida.get("p_domingo").value;
      if (checkp_domingo == true) {
        this.formGroupConfHorarioTipoComida.get("p_domingo").patchValue(1);
        this.b_p_domingo = false;
      } else {
        this.b_p_domingo = true;
        this.formGroupConfHorarioTipoComida.get("p_h_domingo_i").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_h_domingo_f").patchValue('');
        this.formGroupConfHorarioTipoComida.get("p_dia_fin_0").patchValue(0);
      }
    }

    if (this.b_p_lunes && this.b_p_martes && this.b_p_miercoles && this.b_p_jueves && this.b_p_viernes && this.b_p_sabado && this.b_p_domingo) {
      this.validaChecks = true;
    } else {
      this.validaChecks = false;
    }
  }

  openDesactivar(tbl_tipo_comida_id) {
    this.tipoComidaId = tbl_tipo_comida_id;
    this.modalEliminarTipoComidaColaborador = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }
openMensajes(Mensaje: string, Error: boolean,titulo:string) {
  this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, {ariaLabelledBy: "modal-basic-title" });
  this.modalrefMsg.componentInstance.mensajesExito = [];
  this.modalrefMsg.componentInstance.mensajesError = [];
  this.modalrefMsg.componentInstance.mensajeTitulo = 'Tipo de comida';

  if (Error) {
    this.modalrefMsg.componentInstance.showErrors = true;
    this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
  } else {
    this.modalrefMsg.componentInstance.showExitos = true;
    this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
  }
}


}

