import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/services/auth.service';
import { LocalStorageService } from 'src/services/local-storage.service';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { RespuestaGenerica } from '../generales/RespuestaGenerica';
import { ServiciosRutas } from '../generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from '../shared/modulo-modal-mensaje/modulo-modal-mensaje.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [ServiceGenerico],
})
export class LoginComponent implements OnInit {
  @ViewChild("modalreestablecerContrasena", { static: false }) modalreestablecerContrasena: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalRestContra: NgbModalRef;
  private servicios: ServiciosRutas;
  public formLogin: FormGroup;
  public operacionRespuesta: RespuestaGenerica;
  private modelo_configuracion: ServiciosRutas;
  public formVeriCorreo: FormGroup;
  public solicitudExito: boolean;
  controltype ="password";
  showpass=false;


  // formGroupRestablecerContra: FormGroup;

  constructor(
    private services: ServiceGenerico,
    private form: FormBuilder,
    private router: Router,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private authService: AuthService
  ) { 
    this.operacionRespuesta = new RespuestaGenerica();
    this.modelo_configuracion = new ServiciosRutas();
    this.initControlForm();
    this.initControlFormRestablecerPass();
  }

  ngOnInit() {
  }

  private initControlForm(): void {
    this.formLogin = this.form.group({
      usuarioCorreo: ["", Validators.compose([Validators.required]),],
      contrasenia: ["", Validators.required],
    });
  }

  public EjecutarAutenticacion(): void {
    this.operacionRespuesta.EstaEjecutando = true;
    if (this.esValidoElFormulario()) {
      this.services.HttpPost(this.formLogin.value, this.modelo_configuracion.serviciosOperaciones + "/InfoUsuario/PostLogin")
      .subscribe(
        (tempdate) => {
          if (tempdate.response.token != null) {
            var usuario = this.obtenerValoresJWT(tempdate.response.token);
                    localStorage.setItem("jwt", tempdate.response.token);
                    this.mostrarVista(usuario);
          } else {
            this.openMensajes(tempdate.response.mensaje, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }
  }

  private esValidoElFormulario(): boolean {
    if (
      !this.formLogin.get("usuarioCorreo").valid ||
      !this.formLogin.get("contrasenia").valid
    ) {
      this.operacionRespuesta.EsMsjError = false;

      if (!this.formLogin.get("usuarioCorreo").valid) {
        this.operacionRespuesta.Msj = "El usuario o correo no puede ser vacio";
        return false;
      }

      if (!this.formLogin.get("contrasenia").valid) {
        this.operacionRespuesta.Msj =
          "El campo de la contraseña no puede ser vacío";
        return false;
      }
    }

    return true;
  }

  private obtenerValoresJWT(jwt: string): any {
    const helper = new JwtHelperService();

    const informacionToken = helper.decodeToken(jwt);

    return JSON.parse(informacionToken.UserData);
  }

  private mostrarVista(usuario: any): void {
    let ruta: string = "/panel/home";

    this.router.navigate([ruta]);
  }
  showPass() {
    if (this.controltype === "password") {
      this.controltype="text";
      this.showpass=true;
    } else {
     this.controltype= "password";
     this.showpass=false;

    }
  }
  public openModalReestablecerContrasena() {
    this.solicitudExito = false;
    this.formVeriCorreo.reset({usuarioCorreo:''})
    this.modalRestContra = this.modalService.open(this.modalreestablecerContrasena, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'md'  });
  }

  public EnviarCorreoContrasena(): void {
    this.operacionRespuesta.EsMsjError = false;
    if (this.esValidoElFormularioRestablecer()) {
      this.operacionRespuesta.EstaEjecutando = true;
      const params = {
        Correo: this.formVeriCorreo.get("Correo").value, Url: location.origin + "/recuperar-contrasena",
      };
        this.services.HttpPost(params, this.modelo_configuracion.serviciosOperaciones + "/InfoUsuario/ComprobarCorreo")
        .subscribe(
          (tempdate) => {
            if (tempdate.response.length > 0) {
            this.operacionRespuesta.EstaEjecutando = false;
                      this.solicitudExito = true;
            } else {
              this.openMensajes(tempdate.response.mensaje, true, '');
            }
            // if (tempdate.response.mensaje != null) {
            //           this.operacionRespuesta.EsMsjError = true;
            //           this.operacionRespuesta.Msj = tempdate.response.mensaje;
            //         }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
  
    } 
  }
  //#endregion

  //#region Métodos privados
  private initControlFormRestablecerPass(): void {
    this.formVeriCorreo = this.form.group({
      Correo: ["", Validators.compose([Validators.required, Validators.email])],
    });
  }

  private esValidoElFormularioRestablecer(): boolean {
    if (!this.formVeriCorreo.get("Correo").valid) {
      this.operacionRespuesta.EsMsjError = true;

      if (!this.formVeriCorreo.get("Correo").valid) {
        this.operacionRespuesta.Msj = "El formato de correo es invalido";
        return false;
      }
    }
    return true;
  }


  openMensajes(Mensaje: string, Error: boolean,titulo:string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, {ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Inicio de Sesión';
  
    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

}
