import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { Reportes_colaborador_excel_response, Reportes_colaborador_response, Reportes_excel_response, Reportes_response } from 'src/model/Catalogos/Reportes';
import { ServiceGenerico } from 'src/services/service-generico.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { Lista_tipo_comidas_modelo_response } from 'src/model/Catalogos/TipoComida';
import { subscribeOn } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
const EXCEL_EXT = '.xlsx';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css'],
  providers: [ServiceGenerico]
})
export class ReportesComponent implements OnInit {
  @ViewChild("modalConfHorarioColaborador", { static: false }) modalConfHorarioColaborador: TemplateRef<any>;
  modalConfiguracionHorarioColaborador: NgbModalRef;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  public listaColaboradores: Reportes_response[];
  public DetalleColaborador: Reportes_colaborador_response[];
  public listaColaboradoresExcel: Reportes_excel_response[];
  public DetalleColaboradorExcel: Reportes_colaborador_excel_response[];
  listaTipoComidasFiltrado: Array<Lista_tipo_comidas_modelo_response> = [];
  listaTipoComidas: Array<Lista_tipo_comidas_modelo_response> = [];
  public nombre_colaborador: string;
  public numero_empleado: string;
  public cantidad_raciones: number;
  public subtotal: number = 0;  
  muestra_btn_descarga:boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  formGroupFechas: FormGroup;
  iva: number = 0;
  total: number = 0;

  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private fb: FormBuilder,
    private modalService: NgbModal,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
   }

  ngOnInit() {
    this.inicializaTabla();
    this.inicializarFormFechas();
    this.obtenerColaboradores();
    this.obtenerTipoComidas();
  }
  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  inicializarFormFechas() {
    const now = new Date;
    var dia = now.getDate().toString().padStart(2, '0');
    var mes = (now.getMonth()+1).toString().padStart(2, '0');


     this.formGroupFechas = this.fb.group({
      fecha_inicio: [now.getFullYear() + "-" + mes + "-" + dia],
      fecha_fin: [now.getFullYear() + "-" + mes + "-" + dia],
      tbl_tipo_comida_id: [0],
    });
  }

  obtenerColaboradores() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones
        + "/Reportes/GetReporteGeneral?fecha_inicio="+this.formGroupFechas.value.fecha_inicio
        +"&fecha_fin=" +this.formGroupFechas.value.fecha_fin
        +"&tbl_tipo_comida_id="+this.formGroupFechas.value.tbl_tipo_comida_id)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaColaboradores = [] = tempdate.response as Reportes_response[];
            this.muestra_btn_descarga = true;
            this.renderTabla();
          } else {
            this.listaColaboradores = [];
            this.muestra_btn_descarga = false;
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  exportToExcel(json: any[], excelFileName: string, subtotal: number, iva: number, total: number, cantidad: number, fecha: any): void {

    const worksheet = XLSX.utils.aoa_to_sheet([["ORDEN DE SUMINISTRO (RACIONES PARA TRABAJADORES CON DERECHO A COMEDOR)"]]);

    // worksheet['A1'].s = { fill: { patternType: "solid",  fgColor: { rgb: "#111111" } } };
    worksheet['A1'].s = {
	    fill: {
		patternType: "solid", // none / solid
		fgColor: {rgb: "FF000000"},
		bgColor: {rgb: "A50021"}
	    },
	    font: {
		name: 'Times New Roman',
		sz: 16,
		color: {rgb: "#FF000000"},
		bold: false,
		italic: false,
		underline: false
	    },
	    border: {
		top: {style: "thin", color: {auto: 1}},
		right: {style: "thin", color: {auto: 1}},
		bottom: {style: "thin", color: {auto: 1}},
		left: {style: "thin", color: {auto: 1}}
	    }
	};



    XLSX.utils.sheet_add_aoa(worksheet, [["NO.DE FOLIO"]], { origin: "I1" });
    XLSX.utils.sheet_add_aoa(worksheet, [["FECHA INICIO:"]], { origin: "F2" });
    XLSX.utils.sheet_add_aoa(worksheet, [[this.formGroupFechas.value.fecha_inicio]], { origin: "G2" });
    XLSX.utils.sheet_add_aoa(worksheet, [["FECHA FIN:"]], { origin: "H2" });
    XLSX.utils.sheet_add_aoa(worksheet, [[this.formGroupFechas.value.fecha_fin]], { origin: "I2" });
    // Property names: s: start, e: end, c: column, r: row
    worksheet['!merges'] = [{ s: { c: 0, r: 0 }, e: { c: 7, r: 0 } },
    { s: { c: 0, r: 1 }, e: { c: 4, r: 1 } },
    { s: { c: 1, r: 2 }, e: { c: 5, r: 2 } },
    { s: { c: 0, r: 5 }, e: { c: 8, r: 5 } },
    { s: { c: 7, r: 2 }, e: { c: 8, r: 2 } },
    { s: { c: 6, r: 3 }, e: { c: 8, r: 3 } },
    // { s: { c: 0, r: 9 }, e: { c: 4, r: 13 } },
    // { s: { c: 3, r: 18 }, e: { c: 4, r: 18 } },
    { s: { c: 3, r:  json.length + 19 }, e: { c: 4, r: json.length + 25 } },
    { s: { c: 6, r: json.length + 19 }, e: { c: 7, r: json.length + 25 } },
    ];

    XLSX.utils.sheet_add_aoa(worksheet, [["SERVICIO  SOLICITADO"]], { origin: "A3" });
    XLSX.utils.sheet_add_aoa(worksheet, [["DESAYUNO O COMIDA O CENA O COLACIÓN O BOX LUNCH O DIETA LIQUIDA O HIDRATACION COVID"]], { origin: "B3" });
    XLSX.utils.sheet_add_aoa(worksheet, [["OBSERVACIONES:"]], { origin: "G3" });

    XLSX.utils.sheet_add_aoa(worksheet, [["TOTAL DE DIETAS", cantidad, "HORARIO DE ENTREGA:", , "HORA DE RECIBIDO:"]], { origin: "A4" });
    XLSX.utils.sheet_add_aoa(worksheet, [["SECCIÓN"]], { origin: "A5" });

    XLSX.utils.sheet_add_json(worksheet, json, { origin: 'A7' });
    XLSX.utils.sheet_add_aoa(worksheet, [["NÚMERO DE EMPLEADO", "NOMBRE", "APELLIDO", "SERVICIO", "BASE", "OTRO", "CANTIDAD", "PRECIO UNITARIO", "SUBTOTAL"]], { origin: "A7" });


    XLSX.utils.sheet_add_aoa(worksheet, [["TOTAL DE RACIONES", cantidad]], { origin: "F" + (json.length + 8) });

    XLSX.utils.sheet_add_aoa(worksheet, [["SUBTOTAL", , , subtotal]], { origin: "F" + (json.length + 9) });
    XLSX.utils.sheet_add_aoa(worksheet, [["IVA", , , iva]], { origin: "F" + (json.length + 10) });
    XLSX.utils.sheet_add_aoa(worksheet, [["TOTAL", , , total]], { origin: "F" + (json.length + 11) });

    XLSX.utils.sheet_add_aoa(worksheet, [["SOLICITO:", , "REVISO:", , "REVISO:", , "AUTORIZO:"]], { origin: "A" + (json.length + 13) });
    XLSX.utils.sheet_add_aoa(worksheet, [["_______________", , "_______________", , "_______________", , "_______________"]], { origin: "A" + (json.length + 14) });
    XLSX.utils.sheet_add_aoa(worksheet, [["AREA DE NUTRICIÓN", , "COORDINADOR DE RECURSOS HUMANOS", , "COORDINADOR DE SERVICIOS GENERALES", , "SUBDIRECTOR ADMINISTRATIVO"]], { origin: "A" + (json.length + 15) });

    XLSX.utils.sheet_add_aoa(worksheet, [["NOMBRE, FIRMA Y CARGO DE", , "SELLO DEL PROVEEDOR", , , "SELLO JEFATURA DEL AREA DE NUTRICIÓN"]], { origin: "B" + (json.length + 17) });
    XLSX.utils.sheet_add_aoa(worksheet, [["QUIEN RECIBE POR PARTE DEL"]], { origin: "B" + (json.length + 18) });
    XLSX.utils.sheet_add_aoa(worksheet, [["________________________"]], { origin: "B" + (json.length + 19) });
    worksheet["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
   
    worksheet.I1.s = {
	    fill: {
		patternType: "none", // none / solid
		fgColor: {rgb: "FF000000"},
		bgColor: {rgb: "#A50021"}
	    },
	    font: {
		name: 'Times New Roman',
		sz: 16,
		color: {rgb: "#FF000000"},
		bold: false,
		italic: false,
		underline: false
	    },
	    border: {
		top: {style: "thin", color: {auto: 1}},
		right: {style: "thin", color: {auto: 1}},
		bottom: {style: "thin", color: {auto: 1}},
		left: {style: "thin", color: {auto: 1}}
	    }
	};

    

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };


    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcel(excelBuffer, excelFileName);
  }

  private saveAsExcel(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXT)
  }

  exportAsXLSX(): void {
  this.listaColaboradoresExcel = [];
  var _subtotal = 0;
  var _iva = 0;
  var _total = 0;
  var _cantidad = 0;
  var _fecha = new Date();

    for (var i = 0; i <= this.listaColaboradores.length -1; i++) {
      var _listaColaboradoresExcel = new Reportes_excel_response();

      _listaColaboradoresExcel.Número_empleado = this.listaColaboradores[i].numero_empleado;
      _listaColaboradoresExcel.Nombre = this.listaColaboradores[i].nombre;
      _listaColaboradoresExcel.Apellidos = this.listaColaboradores[i].apellido_paterno;
      _listaColaboradoresExcel.Servicio = this.listaColaboradores[i].servicio;
      _listaColaboradoresExcel.Base = "";
      _listaColaboradoresExcel.Otro = "";
      _listaColaboradoresExcel.Cantidad = this.listaColaboradores[i].count_comida_reclamada;
      _listaColaboradoresExcel.Precio_unitario = this.listaColaboradores[i].precio_unitario;
      _listaColaboradoresExcel.Subtotal = this.listaColaboradores[i].subtotal;

      _subtotal += Number(this.listaColaboradores[i].subtotal);
      _iva += Number(this.listaColaboradores[i].subtotal) * 0.16;
      _total =  _subtotal + _iva;
      _cantidad += this.listaColaboradores[i].count_comida_reclamada;

      this.listaColaboradoresExcel.push(_listaColaboradoresExcel);

    }

    this.exportToExcel(this.listaColaboradoresExcel, "Reporte_general", _subtotal, _iva, _total, _cantidad, _fecha.toDateString());
  }
  
  exportAsXLSXColaborador(): void {
    this.DetalleColaboradorExcel = [];
    var _subtotal = 0;
    var _iva = 0;
    var _total = 0;
    var _cantidad = 0;
    var _fecha = new Date();
  
      for (var i = 0; i <= this.DetalleColaborador.length -1; i++) {
        var _DetalleColaboradorExcel = new Reportes_colaborador_excel_response();
  
        _DetalleColaboradorExcel.numero_empleado = this.DetalleColaborador[i].numero_empleado;
        _DetalleColaboradorExcel.nombre = this.DetalleColaborador[i].nombre;
        _DetalleColaboradorExcel.apellido_paterno = this.DetalleColaborador[i].apellido_paterno;
        _DetalleColaboradorExcel.servicio = this.DetalleColaborador[i].servicio;
        _DetalleColaboradorExcel.base = "";
        _DetalleColaboradorExcel.otro = "";
        _DetalleColaboradorExcel.dia_entrega = (this.DetalleColaborador[i].fecha_captura.toString()).split('T')[0];
        _DetalleColaboradorExcel.hora_entrega = (this.DetalleColaborador[i].fecha_captura.toString()).split('T')[1];
        _DetalleColaboradorExcel.precio_unitario = this.DetalleColaborador[i].precio_unitario;
  
        _subtotal += Number(this.DetalleColaborador[i].precio_unitario);
        _iva = Number(_subtotal) * 0.16;
        _total =  _subtotal + _iva;
        _cantidad = this.DetalleColaborador.length;
  
        this.DetalleColaboradorExcel.push(_DetalleColaboradorExcel);
  
      }
  
      this.exportToExcelColaborador(this.DetalleColaboradorExcel, "Reporte_por_colaborador", _subtotal, _iva, _total, _cantidad);
    }

    exportToExcelColaborador(json: any[], excelFileName: string, subtotal: number, iva: number, total: number, cantidad: number): void {

      const worksheet = XLSX.utils.aoa_to_sheet([["ORDEN DE SUMINISTRO (RACIONES PARA TRABAJADORES CON DERECHO A COMEDOR)"]]);
    
  
      XLSX.utils.sheet_add_aoa(worksheet, [["NO.DE FOLIO"]], { origin: "I1" });
      XLSX.utils.sheet_add_aoa(worksheet, [["FECHA INICIO:"]], { origin: "F2" });
      XLSX.utils.sheet_add_aoa(worksheet, [[this.formGroupFechas.value.fecha_inicio]], { origin: "G2" });
      XLSX.utils.sheet_add_aoa(worksheet, [["FECHA FIN:"]], { origin: "H2" });
      XLSX.utils.sheet_add_aoa(worksheet, [[this.formGroupFechas.value.fecha_fin]], { origin: "I2" });
      // Property names: s: start, e: end, c: column, r: row
      worksheet['!merges'] = [{ s: { c: 0, r: 0 }, e: { c: 7, r: 0 } },
      { s: { c: 0, r: 1 }, e: { c: 4, r: 1 } },
      { s: { c: 1, r: 2 }, e: { c: 5, r: 2 } },
      { s: { c: 0, r: 5 }, e: { c: 8, r: 5 } },
      { s: { c: 7, r: 2 }, e: { c: 8, r: 2 } },
      { s: { c: 6, r: 3 }, e: { c: 8, r: 3 } },
      // { s: { c: 3, r: 18 }, e: { c: 4, r: 18 } },
      { s: { c: 3, r:  json.length + 19 }, e: { c: 4, r: json.length + 25 } },
      { s: { c: 6, r: json.length + 19 }, e: { c: 7, r: json.length + 25 } },
      ];
  
      XLSX.utils.sheet_add_aoa(worksheet, [["SERVICIO  SOLICITADO"]], { origin: "A3" });
      XLSX.utils.sheet_add_aoa(worksheet, [["DESAYUNO O COMIDA O CENA O COLACIÓN O BOX LUNCH O DIETA LIQUIDA O HIDRATACION COVID"]], { origin: "B3" });
      XLSX.utils.sheet_add_aoa(worksheet, [["OBSERVACIONES:"]], { origin: "G3" });
  
      XLSX.utils.sheet_add_aoa(worksheet, [["TOTAL DE DIETAS", cantidad, "HORARIO DE ENTREGA:", , "HORA DE RECIBIDO:"]], { origin: "A4" });
      XLSX.utils.sheet_add_aoa(worksheet, [["SECCIÓN"]], { origin: "A5" });
  
      XLSX.utils.sheet_add_json(worksheet, json, { origin: 'A7' });
      XLSX.utils.sheet_add_aoa(worksheet, [["NÚMERO DE EMPLEADO", "NOMBRE", "APELLIDO", "SERVICIO", "BASE", "OTRO", "DIA ENTREGA", "HORA ENTREGA", "PRECIO UNITARIO"]], { origin: "A7" });
  
  
      XLSX.utils.sheet_add_aoa(worksheet, [["TOTAL DE RACIONES", cantidad]], { origin: "F" + (json.length + 8) });
  
      XLSX.utils.sheet_add_aoa(worksheet, [["SUBTOTAL", , , subtotal]], { origin: "F" + (json.length + 9) });
      XLSX.utils.sheet_add_aoa(worksheet, [["IVA", , , iva]], { origin: "F" + (json.length + 10) });
      XLSX.utils.sheet_add_aoa(worksheet, [["TOTAL", , , total]], { origin: "F" + (json.length + 11) });
  
      XLSX.utils.sheet_add_aoa(worksheet, [["SOLICITO:", , "REVISO:", , "REVISO:", , "AUTORIZO:"]], { origin: "A" + (json.length + 13) });
      XLSX.utils.sheet_add_aoa(worksheet, [["_______________", , "_______________", , "_______________", , "_______________"]], { origin: "A" + (json.length + 14) });
      XLSX.utils.sheet_add_aoa(worksheet, [["AREA DE NUTRICIÓN", , "COORDINADOR DE RECURSOS HUMANOS", , "COORDINADOR DE SERVICIOS GENERALES", , "SUBDIRECTOR ADMINISTRATIVO"]], { origin: "A" + (json.length + 15) });
  
      XLSX.utils.sheet_add_aoa(worksheet, [["NOMBRE, FIRMA Y CARGO DE", , "SELLO DEL PROVEEDOR", , , "SELLO JEFATURA DEL AREA DE NUTRICIÓN"]], { origin: "B" + (json.length + 17) });
      XLSX.utils.sheet_add_aoa(worksheet, [["QUIEN RECIBE POR PARTE DEL"]], { origin: "B" + (json.length + 18) });
      XLSX.utils.sheet_add_aoa(worksheet, [["________________________"]], { origin: "B" + (json.length + 19) });
      worksheet["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
     
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  
  
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
      this.saveAsExcel(excelBuffer, excelFileName);
    }

  obtenerTipoComidas() {
    this.listaTipoComidasFiltrado = [];
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/TipoComidas/GetListaTipoComidas?p_estatus=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaTipoComidas = [] = tempdate.response as Lista_tipo_comidas_modelo_response[];
            this.listaTipoComidas.forEach(item => {
              if(item.rel_horario_tipo_comida_id != null){
                this.listaTipoComidasFiltrado.push(item);
              }
            });


          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }



  verDetalleColaborador(tbl_usuario_id) {
    this.obtenerDetalleColaborador(tbl_usuario_id);
    this.modalConfiguracionHorarioColaborador = this.modalService.open(this.modalConfHorarioColaborador, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });

  }

  obtenerDetalleColaborador(tbl_usuario_id) {
    this.subtotal = 0;
    this.DetalleColaborador = [];
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones
        + "/Reportes/GetReporteGeneralColaborador?fecha_inicio="+this.formGroupFechas.value.fecha_inicio
        +"&fecha_fin=" +this.formGroupFechas.value.fecha_fin
        +"&tbl_usuario_id=" +tbl_usuario_id
        +"&tbl_tipo_comida_id="+this.formGroupFechas.value.tbl_tipo_comida_id)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.DetalleColaborador = [] = tempdate.response as Reportes_colaborador_response[];
            this.nombre_colaborador = this.DetalleColaborador[0].nombre + " " + this.DetalleColaborador[0].apellido_paterno
            this.numero_empleado = this.DetalleColaborador[0].numero_empleado;
            this.cantidad_raciones = this.DetalleColaborador.length;

            for (var i = 0; i <= this.cantidad_raciones - 1 ; i ++) {
              this.subtotal = this.subtotal + this.DetalleColaborador[i].precio_unitario;
            }

            this.iva = this.subtotal * 0.16;

            this.total = this.subtotal + this.iva;
          } else {
            this.DetalleColaborador = [];
            // this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }



}
