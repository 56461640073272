import { Component, OnInit } from '@angular/core';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  providers: [ServiceGenerico]
})
export class HomeComponent implements OnInit {
  
  constructor() {

  }

  ngOnInit() {
  }

  getColaboradorInfo(){
    // var numero_empleado = $('#numero_empleado').val();

    // this.operacionRespuesta.EstaEjecutando = true;
    // this.services
    //   .HttpGet(
    //     this.modelo_configuracion.serviciosOperaciones + "/Colaboradores/GetListaColaboradores?p_estatus=1")
    //   .subscribe(
    //     (tempdate) => {
    //       if (tempdate) {
    //         this.listaColaboradores = tempdate.response as Lista_colaboradores_modelo_response;
    //       } else {
    //         this.listaColaboradores = [];
    //       }
    //       this.operacionRespuesta.EstaEjecutando = false;
    //     },
    //     async (err) => {
    //       this.operacionRespuesta.EstaEjecutando = false;
    //     }
    //   );
  }

}
