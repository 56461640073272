import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { usuario_model_response_carga_masiva } from 'src/model/Catalogos/CargaMasiva';
import { ServiceGenerico } from 'src/services/service-generico.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-carga-masiva',
  templateUrl: './carga-masiva.component.html',
  styleUrls: ['./carga-masiva.component.css'],
  providers: [ServiceGenerico]

})
export class CargaMasivaComponent implements OnInit {
  modalrefMsg: NgbModalRef;
  modal: NgbModalRef;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  public valido: boolean;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public ListCargaUsuarios: Array<usuario_model_response_carga_masiva> = [];
  title = 'XlsRead';
  file: File;
  arrayBuffer: any;
  filelist: any;
  public model_usuario_carga_masiva: usuario_model_response_carga_masiva = new usuario_model_response_carga_masiva();
  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;

  constructor(
    private modalService: NgbModal,
    private themeConstants: ThemeConstants,
    private _peticionesService: ServiceGenerico,
  ) { 
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();

  }

  ngOnInit() {
  }

  addfile(event) {
    this.operacionRespuesta.EstaEjecutando = true;
    // this.modal = this.modalService.open(this.modalContent, { size: 'lg' ,backdrop  : 'static', keyboard  : false});

    // this.progressbar = true;
    this.valido = true;
    // this.dtOptions = this.themeConstants.dtOptions;
    // this.dtTrigger = new Subject();

    this.ListCargaUsuarios = [];
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = () => {
      this.model_usuario_carga_masiva = new usuario_model_response_carga_masiva();
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary", dateNF: 'yyyy/mm/dd;@' });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.model_usuario_carga_masiva = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: null }) as unknown as usuario_model_response_carga_masiva;
      for (let i in this.model_usuario_carga_masiva) { 
    
        if (this.model_usuario_carga_masiva[i].Es_Alergico == 'NO') {
          this.model_usuario_carga_masiva[i].Es_Alergico = 0;
        }
        if (this.model_usuario_carga_masiva[i].Requiere_PIN == 'NO') {
          this.model_usuario_carga_masiva[i].Requiere_PIN = 0;
        }      
      }

      this._peticionesService.HttpPost(this.model_usuario_carga_masiva, this.modelo_configuracion.serviciosOperaciones + "/Colaboradores/PostColaboradorMasivo/").subscribe(result => {
        if (result) {
          if (result.response.success == 1) {
            this.openMensajes(result.response.msg, false, '');
            
          } else {
            this.openMensajes(result.response.msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;

   
        }
      },
      async (err) => {
        this.openMensajes("No se pudo realizar la acción", true, '');
        this.operacionRespuesta.EstaEjecutando = false;
      });
    }
    event.srcElement.value = null;
  }
  
  public downloadPdf() {
    var url = location.origin +'/assets/docs/Layout_Bersa_Comedores.xlsx'
    FileSaver.saveAs(url, "Layout_Bersa_Comedores.xlsx");
  }


  openMensajes(Mensaje: string, Error: boolean,titulo:string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, {ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Carga masiva colaboradores';
  
    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

}
