import { Time } from "@angular/common";
import { Timestamp } from "rxjs/internal/operators/timestamp";

export class Lista_horarios_usuario_modelo_response {
     rel_usuario_horario_id: number;
     tbl_usuario_id: number;
     rel_horario_tipo_comida_id: number;
     nombre_tipo_comida: string;
}


export class Lista_horarios_usuario_modelo_request {
    p_rel_usuario_horario_id: number;
    p_usuario_id: number;
    p_rel_horario_tipo_comida_id: string;
}

export class Set_reclamar_usuario_horario_modelo_request
{
    p_rel_usuario_horario_id: number;
}
