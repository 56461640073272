import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { AuthIdentity } from 'src/app/guards/AuthIdentity';
import { AuthService } from 'src/services/auth.service';
import { LocalStorageService } from 'src/services/local-storage.service';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.css'],
  providers: [AuthGuard],

})
export class MenuAdminComponent implements OnInit {
  public isAuth: boolean;
  public banner:string;

  constructor(
    private auth: AuthGuard,
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    this.isAuth = this.auth.canActivate();
    this.authService.estatusActualDelUsuarioEnSesion$().subscribe((isAuth) => {
      this.isAuth = isAuth;
    });

  }



  public cerrarSesion(): void {
    this.auth.logOut();
    this.authService.EsEstaAutenticado(false);
    this.localStorageService.clearToken();

    // this.banner = "";

    this.router.navigate(["/"]);
    // window.location.reload();
  }

}
