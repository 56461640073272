import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { Lista_Conf_horario_tipo_comida_modelo_response, Lista_tipo_comidas_modelo_response } from 'src/model/Catalogos/TipoComida';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Colaborador_modelo_response, Lista_colaboradores_modelo_response, Lista_usuarios_sistema_modelo_response, Usuario_sistema_modelo_response } from 'src/model/Catalogos/Colaboradores';
import { Lista_horarios_usuario_modelo_request, Lista_horarios_usuario_modelo_response } from 'src/model/Catalogos/Horarios';
import { Lista_cpostal_modelo_response, Lista_estado_modelo_response, Lista_municipio_modelo_response } from 'src/model/Catalogos/Generales';

@Component({
  selector: 'app-usuarios-admin',
  templateUrl: './usuarios-admin.component.html',
  styleUrls: ['./usuarios-admin.component.css'],
  providers: [ServiceGenerico]
})
export class UsuariosAdminComponent implements OnInit {
  @ViewChild("modalAddColaborador", { static: false }) modalAddColaborador: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarColaborador: NgbModalRef;
  modalSuspenderColaborador: NgbModalRef;

  formGroupAgregarColaborador: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  listaColaboradores: Lista_usuarios_sistema_modelo_response[];
  Colaborador: Usuario_sistema_modelo_response;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  _tbl_usuario_id: number;
  public usuarioId: string = "";


  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
   }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerColaboradores();
    this.inicializarFormAgregarColaborador();

  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  obtenerColaboradores() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/GetListaUsuariosSistema?p_estatus=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaColaboradores = [] = tempdate.response as Lista_usuarios_sistema_modelo_response[];
            this.renderTabla();
          } else {
            this.listaColaboradores = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarColaborador() {
    this.formGroupAgregarColaborador = this.fb.group({
      p_nombre: ["", Validators.required],
      p_apellido_paterno: ["", Validators.required],
      p_apellido_materno: [""],
      p_correo: ["", Validators.compose([Validators.required, Validators.email])],
      p_telefono: [""],
    });
  }

  agregarColaborador() {
    this.funcionalidad = 'Nuevo';
    this._tbl_usuario_id = 0;
    this.openModalFormColaborador(true);
  }

  GuardarColaborador() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarColaborador.value;
    model.p_tbl_usuario_id = this._tbl_usuario_id;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/PostUsuarioSistema")
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerColaboradores();
            this.modalAgregarColaborador.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/PutUsuarioSistema")
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerColaboradores();
            this.modalAgregarColaborador.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }
  }


  verDetalleColaborador(tbl_usuario_id){
    this.esEditable = false;
    this.openModalFormColaborador(false);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/GetUsuarioSistema_x_id?p_tbl_usuario_id=" + tbl_usuario_id)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Colaborador = tempdate.response as Usuario_sistema_modelo_response;
            this.formGroupAgregarColaborador.value.p_nombre = this.Colaborador[0].nombre;
            this.formGroupAgregarColaborador.value.p_apellido_paterno = this.Colaborador[0].apellido_paterno;
            this.formGroupAgregarColaborador.value.p_apellido_materno = this.Colaborador[0].apellido_materno;
            this.formGroupAgregarColaborador.value.p_correo = this.Colaborador[0].correo;
            this.formGroupAgregarColaborador.value.p_telefono = this.Colaborador[0].telefono;
            this.formGroupAgregarColaborador.patchValue(this.formGroupAgregarColaborador.value);
          } else {
            this.listaColaboradores = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );  
    }

    editarColaborador(tbl_usuario_id) {
      this.funcionalidad = 'Editar';
      this._tbl_usuario_id = tbl_usuario_id;
      this.openModalFormColaborador(true);
      this.operacionRespuesta.EstaEjecutando = true;
      this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/GetUsuarioSistema_x_id?p_tbl_usuario_id=" + tbl_usuario_id)
        .subscribe(
          (tempdate) => {
            if (tempdate) {
              this.Colaborador = tempdate.response as Colaborador_modelo_response;
              this.formGroupAgregarColaborador.value.p_nombre = this.Colaborador[0].nombre;
              this.formGroupAgregarColaborador.value.p_apellido_paterno = this.Colaborador[0].apellido_paterno;
              this.formGroupAgregarColaborador.value.p_apellido_materno = this.Colaborador[0].apellido_materno;
              this.formGroupAgregarColaborador.value.p_correo = this.Colaborador[0].correo;
              this.formGroupAgregarColaborador.value.p_telefono = this.Colaborador[0].telefono;
              this.formGroupAgregarColaborador.patchValue(this.formGroupAgregarColaborador.value);
            } else {
              this.listaColaboradores = [];
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }

    eliminarColaborador(p_tbl_usuario_id) {
      this.operacionRespuesta.EstaEjecutando = true;
      this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/DeleteUsuarioSistema?p_tbl_usuario_id=" + p_tbl_usuario_id.usuarioId)
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerColaboradores();
              this.modalSuspenderColaborador.close();

            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
  openModalFormColaborador(esEditable:boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarColaborador.reset({p_nombre:'', p_apellido_paterno:'', p_apellido_materno:'',p_correo:'', p_telefono:''});
    this.modalAgregarColaborador = this.modalService.open(this.modalAddColaborador, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg'  });
  }

  openDesactivar(tbl_usuario_id) {
    this.usuarioId = tbl_usuario_id;
    this.modalSuspenderColaborador = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }
  openMensajes(Mensaje: string, Error: boolean,titulo:string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, {ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Usuario Sistema';
  
    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
}
